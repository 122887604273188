import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Map() {
  return (
    <Layout>
      <SEO title="Interactive Map" />
      <h1>Interactive Map</h1>
      <p>
        The map shows the CA Assemblymember votes on SB1120 - an anti local
        control measure - and written expressions of support for local control
        (blue pins) and pro local control resolutions (purple gavels) from city
        council members.
      </p>
      <p>
        Examples where the Assemblymember cast a vote at odds with their
        district councilmembers can be seen in Districts 24, 31, and 48. An
        example of where the Assemblymember cast a vote in sync with their
        district councilmembers can be seen in District 66.
      </p>
      <p>
        The SB1120 vote is listed as Y (Yes, district shown in Red), N (No,
        district shown in Blue), or NV (No vote cast, district shown in light
        gray). In a few cases, the SB1120 voting Assemblymember was replaced by
        a New Representative; we show that as "NewRep", the district is shown a
        in darker shade of gray.
      </p>
      <iframe
        src="https://www.google.com/maps/d/embed?mid=10M147SI7e5mTgxnjCUqzQm35OqUq1ecJ"
        width="100%"
        height="800"
        title="Interactive map"
      ></iframe>
    </Layout>
  )
}
